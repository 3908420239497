<template>
  <v-container fluid id="InsumoConsulta" class="pa-0">
    <!-- Cabeçalho do Conteudo -->
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-2 ml-4">
        Consulta de Insumos
      </v-toolbar-title>

      <!-- Botões - Filtro / Novo / Opções INSUMOS-->
      <v-toolbar-title class="mt-0 mb-6 mr-4 ml-4 pa-0" dark elevation="0">
        <div class="d-flex" tabindex="-1">
          <v-container
            class="pa-0 ma-0 mb-7 d-flex"
            style="margin-top: -4px; max-width: 1200px"
          >
            <!-- Input Filtro - Chips / Formulario -->
            <v-menu
              id="vMenu"
              ref="vMenu"
              v-model="menu"
              bottom
              right
              fluid
              offset-y
              origin="top"
              class="pa-0 d-flex flex-wrap"
              elevation="0"
              :close-on-content-click="false"
              @input="abriuConsulta"
            >
              <template v-slot:activator="{ on }">
                <!-- Input do Filtro -->
                <div
                  ref="vMenuDiv"
                  class="false-filter d-flex align-center"
                  v-on="on"
                >
                  <v-icon class="false-icon ml-3 mr-2" color="#FFF">
                    mdi-magnify
                  </v-icon>
                  <!-- Div de todos os chips selecionados -->
                  <div class="false-text flex-wrap">
                    <!-- Chip Codigo Insumo -->
                    <v-chip
                      v-if="store_Insumo.filtro.cod_insumo"
                      pill 
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Codigo:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Insumo.filtro.cod_insumo
                      }}</strong>
                    </v-chip>
                    <!-- Chip Descricao -->
                    <v-chip
                      v-if="store_Insumo.filtro.nome_insumo"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Nome do Insumo:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Insumo.filtro.nome_insumo
                      }}</strong>
                    </v-chip>
                    <!-- Chip Unidade -->
                    <v-chip
                      v-if="store_Insumo.filtro.unidade"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Unidade:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Insumo.filtro.unidade
                      }}</strong>
                    </v-chip>
                     <!-- Chip Categoria do Insumo-->
                    <v-chip
                      v-if="store_Insumo.filtro.categoria"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Categoria:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Insumo.filtro.categoria
                      }}</strong>
                    </v-chip>
                    <!--
                     Chip Subgrupo Insumo 
                    <v-chip
                      v-if="store_Insumo.filtro.subgrupo_insumo"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Subgrupo:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Insumo.filtro.subgrupo_insumo
                      }}</strong>
                    </v-chip>
                    -->                   
                    <!-- Chip Base -->
                    <v-chip
                      v-if="store_Insumo.filtro.base"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Base:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Insumo.filtro.base
                      }}</strong>
                    </v-chip>
                    <!-- Chip Valor Mínimo -->
                    <v-chip
                      v-if="store_Insumo.filtro.valor_unitario_min"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Valor Mínimo:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{ store_Insumo.filtro.valor_unitario_min
                        ? formatNumber(store_Insumo.filtro.valor_unitario_min) : "" }}
                      </strong>
                    </v-chip>
                    <!-- Chip Valor  Máximo -->
                    <v-chip
                      v-if="store_Insumo.filtro.valor_unitario_max"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Valor Máximo:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{ store_Insumo.filtro.valor_unitario_max  
                        ? formatNumber(store_Insumo.filtro.valor_unitario_max) : "" }}
                      </strong>
                    </v-chip>
                    <!-- Chip Estoque Mínimo -->
                    <v-chip
                      v-if="store_Insumo.filtro.estoque_min"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Estoque Mínimo:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{ store_Insumo.filtro.estoque_min }}
                      </strong>
                    </v-chip> 
                    <!-- Chip Estoque Máximo -->
                    <v-chip
                      v-if="store_Insumo.filtro.estoque_max"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Estoque Máximo:&nbsp;
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{ store_Insumo.filtro.estoque_max }}
                      </strong>
                    </v-chip>
                  </div>
                  <v-icon class="false-icon ml-2 mr-4" color="#FFF">
                    mdi-filter-variant-plus
                  </v-icon>
                </div>
              </template>
              <!-- Formulario do Filtro -->
              <v-container
                :style="{ 'background-color': COR_SECUNDARIA_2 }"
                style="
                  min-width: 800px !important;
                  max-width: 1000px !important;
                  margin-bottom: -34px;
                "
              >
                <!-- 1ª Linha do Formulario -->
                <v-row no-gutters class="linhas mb-n2">
                  <v-col md="2">
                    <!-- Input Código -->
                    <v-text-field
                      id="txtCodigo"
                      ref="txtCodigo"
                      dense
                      v-model="filtro_local.cod_insumo"
                      cache-items
                      flat
                      label="Código"
                      placeholder="Código"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="100"
                      clearable
                      tabindex="0"
                      class="mr-2"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Input Descricao -->
                  <v-col md="8">
                    <v-text-field
                      v-model="filtro_local.nome_insumo"
                      item-text="nome_insumo"
                      item-value="nome_insumo"
                      label="Nome Insumo"
                      placeholder="Nome Insumo"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="100"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Input Unidade -->
                  <v-col md="2">
                     <v-autocomplete
                    v-model="filtro_local.unidade"
                    class="mb-0 mr-2"
                    light
                    :items = "arrayUnidades"
                    background-color="#FFF"
                    label="Unidade"
                    placeholder="Unidade"
                    filled
                    outlined
                    dense 
                  />
                  </v-col>
                </v-row>
                <!-- 2ª Linha do Formulario -->
                <v-row no-gutters class="linhas mb-n2">
                  <!-- input categoria -->
                  <v-col md="6">
                   <v-autocomplete
                    v-model="filtro_local.categoria"
                    class="mb-0 mr-2"
                    light
                    :items= "arrayCategorias"
                    background-color="#FFF"
                    label="Categoria"
                    placeholder="Categoria"
                    filled
                    outlined
                    dense
                  />
                  </v-col>
                  <!-- Input Subgrupo Insumo 
                  <v-col md="4">
                    <v-text-field
                      v-model="filtro_local.subgrupo_insumo"
                      item-text="subgrupo_insumo"
                      item-value="subgrupo_insumo"
                      label="Subgrupo"
                      placeholder="Subgrupo"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  -->
                  <!-- Input Base -->
                  <v-col md="6">
                    <v-text-field
                      v-model="filtro_local.base"
                      item-text="base"
                      item-value="base"
                      label="Base"
                      placeholder="Base"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- 3ª Linha do Formulario -->
                <v-row no-gutters class="linhas">
                  <!-- Input valor_unitario -->
                  <v-col md="3">
                    <VuetifyMoney
                      id="txtValorMin."
                      ref="txtValorMin."
                      dense
                      v-model="filtro_local.valor_unitario_min"
                      cache-items
                      flat
                      label="Valor Mínimo"
                      placeholder="Valor Mínimo"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      class="mr-2"
                      :options="{
                        locale: 'pt-BR',
                        prefix: '',
                        suffix: '',
                        length: 11,
                        precision: 4
                      }"
                    ></VuetifyMoney>
                  </v-col> 
                  <!-- Input valor_unitario -->
                  <v-col md="3">
                    <VuetifyMoney
                      id="txtValorMax."
                      ref="txtValorMax."
                      dense
                      v-model="filtro_local.valor_unitario_max"
                      cache-items
                      flat
                      label="Valor Máximo"
                      placeholder="Valor Máximo"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      class="mr-2"
                      :options="{
                        locale: 'pt-BR',
                        prefix: '',
                        suffix: '',
                        length: 11,
                        precision: 4
                      }"
                    ></VuetifyMoney>
                  </v-col> 
                  <!-- Input estoque_min-->
                  <v-col md="3">
                    <v-text-field
                      id="txtEstoqueMin"
                      ref="txtEstoqueMin"
                      dense
                      v-model="filtro_local.estoque_min"
                      cache-items
                      flat
                      label="Estoque Mínimo"
                      placeholder="Estoque Mínimo"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      class="mr-2"
                      type="number"
                    >
                     </v-text-field>
                  </v-col> 
                  <!-- Input estoque_max -->
                  <v-col md="3">
                    <v-text-field
                      id="txtEstoqueMin."
                      ref="txtEstoqueMax."
                      dense
                      v-model="filtro_local.estoque_max"
                      cache-items
                      flat
                      label="Estoque Máximo"
                      placeholder="Estoque Máximo"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      class="mr-2"
                      type="number"
                    >
                    </v-text-field>
                  </v-col> 
                </v-row>
              </v-container>
              <!-- Cria uma linha de divisoria -->
              <v-divider></v-divider>
              <!-- Container Botões - Limpar / Consultar -->
              <div
                class="pa-4 d-flex justify-space-between"
                v-bind:style="{ 'background-color': COR_SECUNDARIA }"
              >
                <!-- Botão Limpar Todos -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  :small="isMobile"
                  @click="limpa_todos()"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-close
                  </v-icon>
                  <span>Limpar Todos</span>
                </v-btn>

                <!-- Botao Consultar -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  tabindex="-1"
                  :small="isMobile"
                  @click="busca(1)"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-magnify
                  </v-icon>
                  <span>Consultar</span>
                </v-btn>
              </div>
            </v-menu>
          </v-container>

          <!-- Espaçamento entre os elementos -->
          <v-spacer></v-spacer>

          <!-- botão Novo  -->
          <router-link
            :to="{ name: 'InsumoContainer' }"
            class="nav-link"
            aria-current="page"
            tabindex="-1"
          >
            <v-btn
              class="text-white text-capitalize"
              :color="COR_PRINCIPAL"
              elevation="0"
              :small="isMobile"
            >
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span>Novo</span>
            </v-btn>
          </router-link>
           <!-- <v-btn
              class="text-white text-capitalize"
              :color="COR_PRINCIPAL"
              elevation="0"
              :small="isMobile"
              @click="CadastrarInsumo()"
            >
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span>Novo</span>
            </v-btn> -->
        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>
    <!-- Tabela -->
    <v-row class="mt-n11 mx-0">
      <!-- Grade  -->
      <v-col cols="12" class="pa-0 mx-0">
        <v-container
          class="container-principal container-rounded rounded-lg mx-0 px-0"
        >
          <!-- Conteiner -------------------------------------- -->
          <v-data-table
          id="virtualScrollTable"
          ref="virtualScrollTable"
          :items="store_Insumo.dados"
          :headers="headers"
          :loading="loading"
          :height="tableHeight"
          :items-per-page="20"
          :hide-default-footer="true"
          :hide-default-header="isMobile"
          :page="currentPage || 1"
          :server-items-length="store_Insumo.count_dados" 
          fixed-header
          dense
          style="white-space: nowrap"
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
            <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
            <template #item="{ item }">
              <tr v-if="!isMobile">
                <!-- Botão de Ações --> 
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="grey" dark icon v-bind="attrs" v-on="on" style="width: 3%">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <!-- Lista de Ações -->
                    <v-list class="py-0">
                      <v-list-item
                        v-for="(opcao, i) in items"
                        :key="i"
                        @click="() => {}"
                        class="px-2"
                      >
                        <!-- Ação de Editar  -->
                        <v-list-item-title v-if="opcao.title == 'Editar'" >
                          <router-link 
                            :to="'/insumocontainer/' + item.cod_insumo"
                            class="nav-link "
                            aria-current="page"
                            tabindex="-1"
                          >
                          <v-icon color="green" class="mr-2 icon-menu">
                              mdi-pencil
                            </v-icon>
                            {{ opcao.title }}
                          </router-link>
                        </v-list-item-title>

                        <!-- Ação de Excluir -->
                        <v-list-item-title v-else-if="opcao.title == 'Excluir'" @click="ModalConfirmacao(item)">
                          <v-icon color="red" class="mr-2 icon-menu" >
                            mdi-delete
                          </v-icon>
                          {{ opcao.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <!-- Campo cod_insumo -->
                <td style="width: 3%">
                  {{ item.cod_insumo }}
                </td>

                <!-- Campo nome_insumo -->
                <td style="width: 25%">
                  <router-link 
                    :to="'/insumocontainer/' + item.cod_insumo"
                    class="nav-link "
                    aria-current="page"
                    tabindex="-1"
                  >
                  <span
                      class="d-flex align-center campos_cor nome_hover"
                      style="font-size: 14px;"
                    >
                      {{ item.nome_insumo }}
                    </span>
                  </router-link>
                </td>

                <!-- Campo categoria -->
                <td style="width: 15%;" class="campos_cor">
                  <div v-if="item.categoria == 'Mão de Obra'">
                    <span class="chip_mao_de_obra">
                      {{ item.categoria }}
                    </span>

                  </div>
                  <div v-if="item.categoria == 'Material'">
                    <span class="chip_material">
                      {{ item.categoria }}
                    </span>
                  </div>
                  <div v-if="item.categoria == 'Equipamentos'">
                    <span class="chip_equipamentos">
                      {{ item.categoria }}
                    </span>
                  </div>
                  <div v-if="item.categoria == 'Administrativo'">
                    <span class="chip_administrativo">
                      {{ item.categoria }}
                    </span> 
                  </div>
                  <div v-if="item.categoria == 'Outros'">
                    <span class="chip_outros">
                      {{ item.categoria }}
                    </span> 
                  </div>
                </td>

                <!-- Campo subgrupo_insumo -->
                <!-- <td style="width: 15%">
                  {{ item.subgrupo_insumo }}
                </td> -->

                 <!-- Campo unidade -->
                <td style="width: 10%" class="campos_cor px-8">
                  {{ item.unidade }}
                </td>
                 <!-- Campo base -->
                <td style="width: 5%" class="campos_cor">
                  {{ item.base }}
                </td>

                 <!-- Campo valor_unitario-->
                <td style="width: 10%; text-align: right" class="campos_cor pr-10">
                  {{ item.valor_unitario ? `R$ ${formatNumber(item.valor_unitario)}` : "" }}
                </td>

                 <!-- Campo estoque  -->
                  <td class="campos_cor">
                  <!-- Disponivel -->
                  <span
                    v-if="item.quantidade > 200"
                    class="status_disponivel"
                    >{{ item.estoque }}
                  </span>

                  <!-- Uso Previsto -->
                  <span
                    v-if="item.quantidade > 0 && item.quantidade < 200"
                    class="status_pendente"
                    >{{ item.estoque }}
                  </span>

                  <!-- Em falta -->
                  <span
                    v-if="item.quantidade >= 0 "
                    class="status_falta"
                    >{{ item.estoque }}
                  </span>
                </td>
              </tr>

              <!-- MOBILE -->
              <tr v-if="isMobile">
                <td style="width: auto">
                  <div class="mt-1" style="font-size: 14px; font-weight: 500">
                    {{ item.pessoa_nome }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.pessoa_email }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.fone_celular1 }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
    <!-- Fim Grade --------------------------------- -->

    <!-- Paginacao -->
    <div
      class="paginacao d-flex justify-end align-center"
      style="background-color: #ffffff"
    >
      <span style="color: grey; font-size: 14px" class="mr-3"
        >{{ store_Insumo.count_dados }} linhas</span
      >
      <v-pagination
        id="pagination"
        ref="pagination"
        v-model="currentPage"
        :length="paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
        class="mt-n1"
        style="color: grey"
      />
    </div>

    <v-dialog
      v-model="dialog_excluir"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR ?
        </v-card-title>

        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_excluir = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            class="btn white--text"
            color="primary accent-4"
            :loading="loading_excluir"
            @click="deletaInsumo()"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="dialog_excluir_alert"
      :timeout="4000"
      :color="dialog_excluir_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{ dialog_excluir_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="dialog_excluir_alert = false"
          class="text-none"
          dark
        >
          <br />
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-container v-if="store_ModalInsumo.dialog_cadastrar_insumo">
      <v-dialog
        v-model="store_ModalInsumo.dialog_cadastrar_insumo"
        transition="dialog-bottom-transition"
        max-width="440px"     
        persistent          
        >
        <v-card>
          <ModalCadastrarInsumo />
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Dialog Confirmação de Exclusão -->
    <v-container>
      <v-dialog v-model="dialogCancelar" transition="dialog-bottom-transition" max-width="410" class="pa-0">
        <v-card elevation="0" class="">
          <v-card-title class="text-h6">
            Deseja realmente Excluir este Registro?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialogCancelar = false">
              Cancelar
            </v-btn>
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="deletaInsumo()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import store_usuario from "../../../store/store_usuario";
import store_ModalInsumo from "./ModalInsumo/store_ModalInsumo";
import store_Insumo from "./store_Insumo";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
} from "../../../services/constantes";
import { formatDate, formatNumber } from "../../../services/funcoes";
import VuetifyMoney from '@/components/ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue';
import ModalCadastrarInsumo from './ModalInsumo/ModalCadastrarInsumo.vue';
import store_site from "../../../store/store_site";
// import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
// import { API } from "../../../services/API";

export default {
  name: "InsumoConsulta",
  components: {
    VuetifyMoney,
    ModalCadastrarInsumo,
  },

  data() {
    return {
      store_usuario: store_usuario,
      store_Insumo: store_Insumo,
      store_ModalInsumo: store_ModalInsumo,
      store_site: store_site,
      formatDate: formatDate,
      formatNumber: formatNumber,

      loading: false,
      search: null,
      search_input_focus: false,
      menu: false,
      empreendimentoObra: [],

      loading_excluir: false,
      filtro: null,
      currentPage: 1,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SECUNDARIA: COR_SECUNDARIA,
      COR_SECUNDARIA_2: COR_SECUNDARIA_2,
      COR_SUBTITULO: COR_SUBTITULO,
      accentColor: "#f00",
      dialogCancelar: false,
      dialog_excluir: false,
      dialog_excluir_alert: false,
      dialog_excluir_msg: false,
      dialog_excluir_message: null,
      dialogIndiceCadastro: false,
      opcoes_situacao: null,
      count_dados: null,
      paginas: null,     
      
      // Opções do filtro unidade e categoria
      arrayCategorias:["Mão de Obra", "Material", "Equipamentos", "Administrativo", "Outros"],
      arrayUnidades:["UN", "CX", "KG", "M²", "M³", "M", "L", "H", "PC", "PCT", 
                     "SC", "MT", "ML", "GL", "TON", "CM", "DZ", "LT", "JG", "G"],

                     
      situacao: [
        "Finalizada",
        "Em Andamento",
        "Parada"
      ],

        // Filtro na Tela de Consulta --------
      filtro_local: {
        cod_insumo: null,
        nome_insumo: null,
        categoria: null,
        unidade: null,
        base: null,
        tipo: null,
        valor_unitario_min: null,
        valor_unitario_max: null,
        // situacao: null,
      },

      data_picker: {
        "min-width": "150px !important",
      },

      /* Opções */
      /* Cabeçalho da Tabela */
      sortBy: [{key: 'cod_obra', direction: 'asc'}],
      headers: [
        {text: "", value: "", sortable: false},
        { text: "Código", value: "cod_insumo", sortable: true },
        { text: "Insumo", value: "nome_insumo", sortable: true },
        { text: "Categoria", value: "categoria", sortable: true },
        { text: "Unidade", value: "unidade", sortable: true },
        { text: "Base", value: "base", sortable: true },
        { text: "Valor Unitário ", value: "valor_unitario", sortable: true },
        { text: "Estoque", value: "quantidade", sortable: true },
      ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],
    };
  },

  async created() {
    this.busca();
  },

  async mounted() {
  },

  watch: {
    search(val) {
      // console.log('WATCH', val)
      this.currentPage = 1;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    tableHeight() {
      if (this.isMobile) return window.innerHeight - 149 + 30 - 90;
      else return window.innerHeight - 149 - 55;
    },
  },

 methods: {
    ModalConfirmacao(item){
      this.store_Insumo.insumo_selecionado = item;
      this.dialogCancelar = true;
    },

    async deletaInsumo() {
      let lo_Res = null;
      this.dialogCancelar = false;
      lo_Res = await this.store_Insumo.InsumoDelete(this.store_Insumo.insumo_selecionado);
      if (lo_Res.status == 200) {
        this.store_site.alert_cor = "#00A000"; // Cor verde para sucesso
        this.store_site.alert_timeout = 1500; // Tempo para fechar o alerta
        this.store_site.alert_msg = lo_Res.data.result.result.trim(); // Mensagem de sucesso
        this.store_site.alert = true;
      } else {
        this.store_site.alert_cor = "#FF0000"; // Cor vermelha para erro
        this.store_site.alert_timeout = 10000; // Tempo para fechar o alerta
        this.store_site.alert_msg = lo_Res.data.result.trim() // Mensagem de erro
        this.store_site.alert = true;
        console.log("Erro ao excluir insumo: ", lo_Res);
      }
      this.busca();
    },

    EditarInsumo(item) {
      this.store_ModalInsumo.acao = "E";
      this.store_ModalInsumo.cadastro_insumo = {...item,
      };
      this.store_ModalInsumo.dialog_cadastrar_insumo = true;
    },

    CadastrarInsumo() {
      this.store_ModalInsumo.acao = "I";
      this.store_ModalInsumo.cadastro_insumo = {};
      this.store_ModalInsumo.dialog_cadastrar_insumo = true;
    },
    
    handlePageChange(value) {
      this.currentPage = value;
    },
    
    limpa_todos() {
      for (let prob in this.filtro_local) {
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null;
      }
      this.store_Insumo.filtro = {};
    },
    

  // Filtro
    async busca() {
      this.loading = true;
      this.menu = false;
    
      //Busca valor Minimo
      this.filtro_local.valor_unitario_min 
        ? (this.filtro_local.valor_unitario_min = Number 
        (this.filtro_local.valor_unitario_min)) : null;

      //Busca valor Maximo
      this.filtro_local.valor_unitario_max 
        ? (this.filtro_local.valor_unitario_max = Number
          (this.filtro_local.valor_unitario_max)) : null;

      //Opções de busca do Filtro
      this.store_Insumo.filtro = { ...this.filtro_local }; 
      var lo_params = { ...this.store_Insumo.filtro };

      let lo_Res 
      lo_Res = await this.store_Insumo.InsumosGet(lo_params); 

      // Filtro Orçamento A Fazer
      this.loading = false;

    },
    abriuConsulta(val) {
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = { ...this.store_Insumo.filtro };
        });
      }
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
  },
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.icon-filter {
  font-size: 20px;
}

.false-filter {
  background-color: #6c87a2;
  height: 38px !important;
  min-width: 400px !important;
  max-width: 1000px!important;
  border-radius: 5px;
  justify-content: space-between;
}

  .false-text {
    width: calc(100%);
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: #C9CBCA;
    cursor: pointer;
    overflow-x: hidden;
  }

table tr {
  width: 100%;
}
.campos_cor{
  color: #808080
  
}
.chip_mao_de_obra {
  color: #00a5cf;
  border: 1px solid #00a5cf;
  background-color: rgba(0, 166, 207, 0.075);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
  font-weight: bold;
}

.chip_material {
  color: #FFAB24;
  border: 1px solid #FF7F08;
  background-color: rgba(255, 127, 8, 0.078);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
  font-weight: bold;
}

.chip_equipamentos {
  color:  #FFC200;
  border: 1px solid #FFBB01;
  background-color: rgba(255, 225, 0, 0.148);
  font-weight: bold;
  border-radius: 15px;
  padding: 2px 12px;
  font-size: 12px !important;
}
.chip_administrativo {
  color: #0ead69;
  border: 1px solid #0ead69;
  background-color: rgba(0, 199, 50, 0.087);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
  font-weight: bold;
}
.chip_outros {
  color: #8b8c89;
  border: 1px solid #8b8c89;
  background-color: rgba(139, 140, 137, 0.076);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
  font-weight: bold;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.status_disponivel {
  font-size: 12px !important;
  background: #16c172;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 12px !important;
  background: #ffc857;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_falta {
  font-size: 12px !important;
  background: #db3a34;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.width-adress {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

.customer {
  display: block;
  font-weight: 600;
}

.email {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
  opacity: 0.8;
}

.v-btn {
  display: inline-block;
}

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.search-input,
.search-input-longo {
  min-width: 400px;
  max-width: 400px;
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

@media (max-width: 1024px) {
  .nav-link {
    padding-right: 0px !important;
  }
}


/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #fff !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  .container-principal {
    max-width: 100% !important;
  }
}

.v-menu__content {
  z-index: 10 !important;
}

/* Verificar com o Fernando */
.cor-subtitulo {
  color: var(--COR_SUBTITULO);
}

.nome_hover:hover{
  cursor: pointer;
  font-weight: bold;
  color: var(--COR_SUBTITULO) !important;

}

</style>
