<template>
  <div id="ModalCadastrarInsumo" class="pa-0">
    <v-card class="container pa-0">
      <v-card-title
        v-bind:style="{ 'background-color': COR_PRINCIPAL }"
        class="justify-space-between py-2 px-3"
      >
        <span class="text-white title-page body-1">Cadastro de Insumo</span>
        <v-btn
          icon
          dark
          color="#F2F6F7"
          @click="cancelar()" 
        >
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-2 pb-1">
        <v-container>
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" >              
                <!-- Nome -->
                <v-text-field 
                  v-model="store_ModalInsumo.cadastro_insumo.nome_insumo"
                  class="mb-0"
                  light
                  background-color="#FFF"
                  label="Nome do insumo"
                  placeholder="Nome do insumo"
                  filled
                  outlined
                  maxlength="100"
                  required
                  :rules="nomeInsumoRules"
                  dense
                  autofocus
                />
                <div>
                  <!-- Unidade -->  
                  <v-autocomplete
                    v-model="store_ModalInsumo.cadastro_insumo.unidade"
                    class="mb-0"
                    light
                    :items = "arrayUnidades"
                    background-color="#FFF"
                    label="Unidade"
                    placeholder="Unidade"
                    filled
                    outlined
                    dense 
                  />
                  <!-- Categorias -->
                  <v-autocomplete
                    v-model="store_ModalInsumo.cadastro_insumo.categoria"
                    class="mb-0"
                    light
                    :items= "arrayCategorias"
                    background-color="#FFF"
                    label="Categoria"
                    placeholder="Categoria"
                    filled
                    outlined
                    dense
                  />
                  <!-- Valor Unitário -->
                  <VuetifyMoney
                    id="valor_unitario"
                    ref="valor_unitario"
                    dense
                    v-model="store_ModalInsumo.cadastro_insumo.valor_unitario"
                    cache-items
                    flat
                    label="Valor Unitário"
                    placeholder="Valor Unitário"
                    outlined
                    filled
                    background-color="#FFF"
                    clearable
                    :options="{
                      locale: 'pt-BR',
                      prefix: '',
                      suffix: '',
                      length: 11,
                      precision: 4
                    }"
                  ></VuetifyMoney>  
                </div>            
              </v-col>
            </v-row>
          </v-form>
            <v-footer 
              color="transparent"
              elevation="0"
              class="footer justify-center mt-7 pt-3"
            >
            <v-btn
              color="primary"
              class="mr-4 caption font-weight-medium"
              text
              @click="cancelar()"
              id="btn-cancelar"
              ref="btn-cancelar"
            >
              Cancelar
            </v-btn>

            <v-btn 
              :disabled="!valid"
              color="primary accent-4" 
              class="btn caption font-weight-medium white--text" 
              @click="cadastrar()"
            >
              Cadastrar Insumo
            </v-btn>
            </v-footer>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

  <script>
  import store_ModalInsumo from "./store_ModalInsumo";
  import store_site from "../../../../store/store_site";
  import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA } from "../../../../services/constantes";
  import VuetifyMoney from '../../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue';
  import store_Insumo from "../store_Insumo";
  import InsumoConsulta from '../InsumoConsulta.vue';
  // import { formatNumber } from "../../../../services/funcoes";
  // import { API } from "../../../../services/API";
  
  export default {
  components: { VuetifyMoney },
    name: "ModalCadastrarInsumo",


    data() {
      return {
        store_ModalInsumo       : store_ModalInsumo,
        store_site              : store_site,
        store_Insumo            : store_Insumo,
        InsumoConsulta          : InsumoConsulta,

        COR_PRINCIPAL           : COR_PRINCIPAL,
        COR_SUBTITULO           : COR_SUBTITULO,
        COR_SECUNDARIA          : COR_SECUNDARIA,
        arrayTipos              : ["Etapa", "Item"],
        arrayCategorias         : ["Mão de Obra", "Material", "Equipamentos", "Admistrativo", "Outros"],
        arrayUnidades           : ["UN", "CX", "KG", "M²", "M³", "M", "L", "H", "PC", "PCT", "SC", "MT", "ML", "GL", "TON", "CM", "DZ", "LT", "JG", "G"],

        valid: true,

        nomeInsumoRules: [
          (value) =>
            (!!value) || "O nome é obrigatório",
          (value) =>
            (value && value.length <= 100) || "O nome deve ter no máximo 100 caracteres",
        ],
        
        tipoRules: [
          (value) =>
            (!!value) ||
            "O tipo é obrigatório",
        ],

        valorUnitarioRules: [
          (value) =>
            (value && /^\d{0,15}(\.\d{0,4})?$/.test(value)) || "O valor unitário deve ter no máximo 15 dígitos antes da vírgula e 4 dígitos depois da vírgula",
        ],
  
      };
    },

    mounted() {
    },

    methods: {
      cancelar() {
        this.store_ModalInsumo.dialog_cadastrar_insumo = false;
        this.store_ModalInsumo.cadastro_insumo = {}
      },

      async cadastrar() { 
        let ls_Rel = null

        this.store_ModalInsumo.cadastro_insumo.valor_unitario 
        ? (this.store_ModalInsumo.cadastro_insumo.valor_unitario = Number
        (this.store_ModalInsumo.cadastro_insumo.valor_unitario)) : null;

        if(this.store_ModalInsumo.acao == 'I') {
          ls_Rel = await this.store_ModalInsumo.InsumoPost(this.store_ModalInsumo.cadastro_insumo)
        } else {
          ls_Rel = await this.store_ModalInsumo.InsumoPut(this.store_ModalInsumo.cadastro_insumo)
        }
        if (ls_Rel.status == 200) {
          console.log("Insumo cadastrado com sucesso");
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Rel.data.result.result;
          this.store_site.alert = true;
          this.store_ModalInsumo.dialog_cadastrar_insumo = false;
          this.$emit('close');
        } else {
          console.log("Erro ao cadastrar insumo");   
          this.store_site.alert_cor = "#FF0000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Rel.data.errors;
          this.store_site.alert = true;
        } 
        this.store_Insumo.InsumosGet({ cod_insumo: this.store_ModalInsumo.cadastro_insumo.cod_insumo });
      },
    },
  };
</script>
  
  <style scoped>
  .container {
  background: #f2f6f7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
}
</style>
  