<template>
  <div
    id="InsumoContainer"
    class="container-fundo pa-0"
    v-bind:style="{ 'background-color': COR_PRINCIPAL }"
  >
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title
        class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0"
      >
        <!-- Botão Voltar -->
        <router-link
          :to="{ name: 'InsumoConsulta' }"
          class="nav-link"
          aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action">
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </router-link>
        <!-- fim botão voltar -->
        Insumo
      </v-toolbar-title>
    </v-toolbar-title>
     
    <!-- Container - Cabeçalho Info. Obra / Menu Navegação / Container Abas -->
    <v-container class="container rounded-lg mt-n10 pa-0">
      <v-card elevation="0" class="card d-flex flex-column pb-0">
        <v-card-text class="card-content my-0 py-0">
          <!-- Cabeçalho Info. Obra - Avatar / Nome / Demais Dados -->
          <v-row class="d-none d-sm-block wrapper-top rounded-tl-lg my-0 py-0 px-0">
            <v-col class="col-top rounded-t-lg ma-0 pa-0">
              <v-sheet class="cabecalho d-flex align-center mx-auto px-4 py-2">
                <!-- Avatar do Insumo -->
                <div class="d-none d-sm-flex justify-center pr-4">
                  <v-icon
                    size="80px"
                    :style="{ color: COR_PRINCIPAL }"
                  >
                    mdi-wall
                    <span
                      v-if="!store_Insumo.insumo_selecionado.foto_insumo"
                      color="#274ABB"
                      class="headline"
                    >
                    </span>
                    />
                  </v-icon>
                </div>

                <!-- Nome do Insumo -->
                <v-toolbar-title
                  class="text-wrap title-page body-1 d-inline-flex align-center font-weight-bold"
                  :style="{color: COR_PRINCIPAL, width: '400px !important'}"
                >
                  {{ store_Insumo.insumo_selecionado.nome_insumo }}
                </v-toolbar-title>

                <!-- divider vertical -->
                <v-divider vertical class="divisor-vertical px-2"></v-divider>

                <!-- Campo Informações Insumo -->
                <v-col class="d-flex flex-column pl-4">

                  <!-- Primeira linha de informações -->
                  <v-row no-gutters class="d-flex align-center ma-0 mb-2"> 
                    <!-- Campo Código -->
                    <span
                      class="header-text body-2 mr-3 campos_style"
                    >
                      Código:
                      <span :style="{ color: COR_PRINCIPAL}">
                        {{ store_Insumo.insumo_selecionado.cod_insumo }}
                      </span>
                    </span>
                    <!-- Campo Categoria -->
                    <span
                      style="white-space: nowrap"
                      class="header-text body-2 campos_style mr-3"
                    >
                      Categoria:
                      <!-- Mão de Obra -->
                      <span v-if="store_Insumo.insumo_selecionado.categoria == 'Mão de Obra'">
                          <span 
                            class="chip_mao_de_obra"
                          >
                            {{ store_Insumo.insumo_selecionado.categoria }}
                          </span>
                      </span>
                      <!-- Material -->
                      <span v-else-if="store_Insumo.insumo_selecionado.categoria == 'Material'">
                          <span 
                            class="chip_material"
                          >
                            {{ store_Insumo.insumo_selecionado.categoria }}
                          </span>
                      </span>
                      <!-- Equipamentos -->
                      <span v-else-if="store_Insumo.insumo_selecionado.categoria == 'Equipamentos'">
                          <span 
                            class="chip_equipamentos"
                          >
                            {{ store_Insumo.insumo_selecionado.categoria }}
                          </span>
                      </span>
                      <!-- Administrativo -->
                      <span v-else-if="store_Insumo.insumo_selecionado.categoria == 'Administrativo'">
                          <span 
                            class="chip_administrativo"
                          >
                            {{ store_Insumo.insumo_selecionado.categoria }}
                          </span>
                      </span>
                      <!-- Outros -->
                      <span v-else-if="store_Insumo.insumo_selecionado.categoria == 'Outros'">
                          <span 
                            class="chip_outros"
                          >
                            {{ store_Insumo.insumo_selecionado.categoria }}
                          </span>
                      </span>
                      <!-- Nenhum  -->
                      <span v-else>
                        <span 
                        >
                          {{ store_Insumo.insumo_selecionado.categoria }}
                        </span>
                      </span>
                    </span>
                    <!-- Campo Subgrupo -->
                    <!-- <v-col md="3">
                      <span
                        class="header-text body-2 font-weight-medium mr-3"
                        :style="{ color: COR_PRINCIPAL }"
                      >
                        Subgrupo:
                        <span :style="{ color: COR_PRINCIPAL }">
                          {{ store_Insumo.insumo_selecionado.subgrupo_insumo }}
                        </span>
                      </span>
                    </v-col> -->
                  </v-row>
                  <!-- Segunda Coluna de Informações -->
                  <v-row no-gutters class="d-flex align-center ma-0">                    <!-- Campo de Unidade -->
                    <span
                      class="header-text body-2 campos_style mr-3"
                    >
                      Unidade:
                      <span :style="{ color: COR_PRINCIPAL }">
                        {{ store_Insumo.insumo_selecionado.unidade }}
                      </span>
                    </span>
                    <!-- Campo de Base -->
                    <!-- <v-col md="3">
                      <span
                        class="header-text body-2 font-weight-medium mr-3"
                        :style="{ color: COR_PRINCIPAL }"
                      >
                        Base:
                        <span :style="{ color: COR_PRINCIPAL }">
                          {{ store_Insumo?.insumo_selecionado.base }}
                        </span>
                      </span>
                    </v-col> -->
                    <!-- Campo de Valor da Última Compra -->
                    <span
                      class="header-text body-2 campos_style mr-3"
                    >
                      Valor da última compra:
                      <span :style="{ color: COR_PRINCIPAL }">
                        {{
                          store_Insumo.insumo_selecionado.valor_unitario
                        
                        }}
                      </span>
                    </span>
                  </v-row>
                </v-col>
                <!-- Fim Campo Informações de Insumos -->
                <!-- divisor para o botão opções -->
                <v-divider vertical class="divisor-vertical"></v-divider>
              </v-sheet>
                <v-divider class="divisor" style="width: 100%"></v-divider>
            </v-col>
          </v-row>
          <v-row class="wrapper-bottom my-0 py-0 px-1">
            <v-col
              class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0"
            >
              <!-- Menu de Navegacao - Aba Dados / Estoque / Historico de Compra -->
              <v-list
                v-if="expand"
                class="list rounded-l-lg overflow-y-scroll pt-1"
                dense
              >
                <v-list-item-group
                  v-model="itemSelecionado"
                  active-class="bg-active"
                  mandatory
                >
                  <!-- Chama os dados da lista de store_Insumo.abas e coloca separador entre eles -->
                  <template v-for="[icon, text] in store_Insumo.abas">
                    <!-- SEPARADOR ------------------------------->
                    <v-divider
                      v-if="text == 'separador'"
                      :key="icon"
                      class="my-1">
                    </v-divider>
                    <v-list-item
                      v-else
                      :key="icon"
                      :disabled="store_Insumo.acao != 'C'"
                    >
                      <!-- Exibe icones -->
                      <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                        <v-icon class="size-icon">{{ icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content
                        v-if="text != 'separador'"
                        class="mr-n2"
                      >
                        <!-- Icone de Ok -->
                        <!-- <v-badge
                          v-if="quant && quant == 'OK'"
                          icon="mdi-check"
                          :value="quant"
                          color="green accent-9"
                          offset-x="20"
                          offset-y="18"
                        >
                        </v-badge> -->

                        <!-- icone de erro -->
                        <!-- <v-badge
                          v-else-if="quant && Number(quant) > 0"
                          :content="quant"
                          :value="quant"
                          color="red accent-1"
                          offset-x="20"
                          offset-y="18"
                        >
                        </v-badge> -->

                            <!-- Itens Menu Navegacao -->
                            <v-list-item-title class="new-font">{{
                              text
                            }}</v-list-item-title>
                            <!-- ------------------------------------ -->
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              <!-- Exibe somente os Icones quando a lista é recuada -->
              <v-list v-else dense>
                <template v-for="[icon, text] in store_Insumo.abas">
                  <v-divider
                    v-if="text == 'separador'"
                    :key="icon"
                    class="my-1">
                  </v-divider> 
                  <v-list-item
                    v-else
                    :key="icon"
                    :disabled="store_Insumo.acao != 'C'"
                  >
                    <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                      <v-icon class="size-icon">{{ icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list>
              <!-- Botão Expandir e Recuar do Menu Navegacao -->
              <v-btn
                class="btn-expanded ma-0 pa-0"
                @click="expand = !expand"
                :style="`${expand ? '' : 'right: -10px!important;'}`"
              >
                <v-icon
                  v-if="expand == false"
                  class="size-icon-expanded ma-0 pa-0"
                  >mdi-chevron-right                    
                </v-icon>
                <v-icon 
                  v-else 
                  class="size-icon-expanded ma-0 pa-0"
                  >mdi-chevron-left
                </v-icon>
              </v-btn>
              <!-- fim do botãObraCadastroPessoa_DadosBasicoso Expandir e Recuar aba esquerda-->
            </v-col>
            <!-- FIM COL ESQUERDA ---------------->

            <v-divider class="divisor-vertical" vertical></v-divider>

            <!-- COL DIREITA -------------------->
            <v-col
              v-if="itemSelecionado == 0"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <InsumoCadastro />
            </v-col>
            <!-- Dados do Insumo -->
            <v-col
              v-if="itemSelecionado == 1"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
             <InsumoEstoque />
            </v-col>

            <v-col
              v-if="itemSelecionado == 2"
              class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-3"
            >
              <InsumoHistorico />
            </v-col>
            
            <!-- Tasks do Mobile -------->
            <v-tabs-items
              v-if="isMobile"
              v-model="tab"
              class="ma-2 mb-5 mt-0"
              style="background-color: transparent"
            >
              <v-tab-item v-for="column in columns" :key="column.title">
                <div>{{ column.itemSelecionado }}</div>
              </v-tab-item>
            </v-tabs-items>
            <!-- FIM COL DIREITA ------------------------------->
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
} from "../../../services/constantes";
import store_Insumo from "./store_Insumo";
import InsumoHistorico from "./InsumoHistorico.vue";
import InsumoCadastro from "./InsumoCadastro.vue";
import store_usuario from "../../../store/store_usuario";
  import InsumoEstoque from "./InsumoEstoque.vue";
import {
  formatNumber,
  maskCpfCnpj,
  formatDate,
  nomeIniciais
} from "../../../services/funcoes";
import { baseURL } from "../../../services/API";
import ModalCadastrarInsumoVue from './ModalInsumo/ModalCadastrarInsumo.vue';

export default {
  components: {
    InsumoCadastro,
    InsumoEstoque,
    InsumoHistorico,
  },

  data() {
    return {
      store_Insumo: store_Insumo,
      store_usuario: store_usuario,
      nomeIniciais: nomeIniciais,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SUBTITULO: COR_SUBTITULO,
      COR_SECUNDARIA: COR_SECUNDARIA,
      baseURL: baseURL,

      formatDate: formatDate,
      formatNumber: formatNumber,
      maskCpfCnpj: maskCpfCnpj,

      expand: true,

      tab: null,

      itemSelecionado: 0,

      columns: [],

      abas_mobile: [
        "Dados Básicos",
        "Documentos",
        "Privilégios",
        "Dados Teste",
        "Documentos Teste",
        "Privilégios Teste",
      ],
    };
  },

  computed: {
    ImagemCapaCaminhoCompleto: function () {
      var img = "";
      // console.log(!!this.store_Insumo.insumo_selecionado.vendas_imovel?.imovel_imagem[0]);
      if (!!this.store_Insumo.insumo_selecionado?.vendas_imovel?.imovel_imagem[0]) {
        img =
          this.baseURL +
          "imagens/imoveis/fotos/empresa_" +
          this.store_usuario.user.cod_usuario +
          "/" +
          this.store_Insumo.insumo_selecionado?.vendas_imovel?.imovel_imagem[0].imagem_caminho;
      }
      return img;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  async mounted() {
    var lo_params = { cod_insumo: this.$route.params.cod_insumo };
    if (lo_params.cod_insumo) {
      this.store_Insumo.acao = "C";
      const lo_Res = await this.store_Insumo.InsumoGet(lo_params);
      if (lo_Res) {
        this.store_Insumo.insumo_selecionado = { ...lo_Res };
      }
      // console.log("this.store_Insumo.dados", this.store_Insumo.dados)
    }
    // Inclusão insumo_selecionado -----------------------------------------------
    else {
      this.store_Insumo.acao = "I";
      this.store_Insumo.insumo_selecionado = { 
        cno: "",
        cod_insumo: null,
        cod_pessoa: null,
        dt_previsao_inicio: "",
        dt_previsao_termino: "",
        dt_realizacao_inicio: "", 
        dt_realizacao_termino: "",
        empreitada: null,
        endereco_cobranca: [],
        nome_cliente: "",
        nome_insumo: "",
        valor_previsto: null,
        valor_realizado: null,
      };

    }
  },

  methods: {
  },
};
</script>

<style scoped lang="scss">
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 17px !important;
}

.column-layout {
  column-count: 2; /* Define duas colunas */
  column-gap: 20px; /* Espaço entre as colunas */
  }

@media (max-width: 599px) {
  .title-page {
    width: 100% !important;
  }

  .text-opacity {
    opacity: 0.7;
  }

  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #fff !important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100% !important;
  height: calc(100vh - 110px) !important;
}

@media (max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 160px) !important;
    margin-top: -4px !important;
  }
}

.card {
  height: 100% !important;
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA) !important;
}

.card-content {
  height: 100% !important;
}

.wrapper-top {
  height: 105px !important;
}

.header-text {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 105px) !important;
}

.divisor {
  height: 100%;
}

@media (max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column !important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor {
    display: none;
  }
}

.col-top {
  width: 100vw;
}

.col-esquerda {
 max-width: 300px !important;
  height: 100%;
  position: relative;
  background-color: #f7f7f7;
  margin-top: -2px;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  width: 70%;
  overflow-y: auto;
}

@media (max-width: 599px) {
  .col-direita {
    padding-right: 4px !important;
    padding-left: 0px !important;
    width: 100%;
  }
}

.list {
  max-width: 300px !important;
  min-width: 240px !important;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

// .v-list-item-content
// .v-list-item-title {
//   color: #FF0000;
//   background-color: #FF0000;
//   padding-right: 5px!important;
// }

// .active_list .v-list-group .v-list-item--active {
//   color: #0000FF !important;
//   background-color: #FF0000;
// }

// .v-list-tile {
//   color: #FF0000;
//   background-color: #FF0000;
// }

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff !important;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL) !important;
  color: white !important;
}

.cabecalho {
  background-color: #f7f7f7;
  
}

@media (min-width: 900px) {
  .cabecalho {
    max-height: 100px;
    height: 100px !important;
    min-height: 100px;
    
  }
}
.divisor-vertical {
  // display: none;
  height: 100% !important;
  position: relative;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}
.custom-divider {
  height: 50px; /* Ajuste o valor conforme necessário */
}

.col-esquerda-collapsed {
  width: 50px; /* Ajuste conforme necessário */
  overflow: hidden;
}

.col-esquerda-expanded {
  width: 300px; /* Ajuste conforme necessário */
}

.chip_mao_de_obra {
  color: #00a5cf;
  border: 1px solid #00a5cf;
  background-color: rgba(0, 166, 207, 0.075);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
}

.chip_material {
  color: #FFAB24;
  border: 1px solid #FF7F08;
  background-color: rgba(255, 127, 8, 0.078);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
}

.chip_equipamentos {
  color:  #FFC200;
  border: 1px solid #FFBB01;
  background-color: rgba(255, 225, 0, 0.148);
  border-radius: 15px;
  padding: 2px 12px;
  font-size: 12px !important;
}
.chip_administrativo {
  color: #0ead69;
  border: 1px solid #0ead69;
  background-color: rgba(0, 199, 50, 0.087);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
}
.chip_outros {
  color: #8b8c89;
  border: 1px solid #8b8c89;
  background-color: rgba(139, 140, 137, 0.076);
  border-radius: 25px;
  padding: 2px 12px;
  font-size: 12px !important;
}

.campos_style{
  font-weight: bold;
  color: rgba(45, 118, 160, 0.808)
}
</style>
